.event-source {
  dl {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    overflow: visible;


    dt,
    dd {
      padding: 4px 0;
    }

    dt {
      font-weight: 600;
      flex: 0 1 30%;
      text-align: right;
      padding-right: 8px;

      &:after {
        content: ":";
        margin-left: 8px;
      }
    }

    dd {
      flex: 1 1 70%;
      text-align: left;
    }
  }

  .field-error {
    position: absolute;
    top: -2px;
    right: 0;
  }
}