@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-base/sass/ag-theme-base-mixin";
@import "theme/overrides/Colors.scss";

.ems-grid-container {
  @include ag-theme-base((grid-size: 3px,
      background-color: $ui-0,
      odd-row-background-color: $ui-100,
      secondary-border-color: $ui-400,
      cell-horizontal-border: solid ag-derived(secondary-border-color),
      cell-horizontal-padding: 8px,
    ));

  height: 100%;
  font-size: 0.8em;

  &.editing {
    padding-bottom: 48px;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-cell {
    border-bottom: 1px solid ag-param(secondary-border-color);
    color: $ui-800;
    line-height: 25px;
    height: 25px;

    .ag-input-field-input {
      padding: 0 4px;
      border: solid 1px $ui-900;
      background-color: $ui-0  !important;
      color: $ui-1000  !important;
    }

    &.ag-cell-auto-height {
      &.ag-cell-wrap-text {
        min-height: 100%;
      }
    }

    &.selector-cell {
      background-color: $ui-400;
    }

    &.ag-cell-range-single-cell {
      &.no-border {
        border: none !important;
      }
    }
  }

  .ag-header {
    background-color: $ui-100;
    color: $ui-1000;
    border-bottom: 1px solid ag-param(secondary-border-color);

    .ag-header-cell {
      border-right: 1px solid ag-param(secondary-border-color);

      &.readonly {
        background-color: $ui-200;
      }
    }

    .ag-header-cell-label {
      padding: 8px 4px 8px 0;
      align-items: start;

      .ag-header-cell-text {
        white-space: normal !important;
      }

      .ag-sort-ascending-icon,
      .ag-sort-descending-icon {
        position: absolute;
        right: 2px;
        bottom: 4px;
      }
    }
  }

  .ag-row {
    border: none;

    &.manual-record {
      background-color: $highlight-500;

      &.ag-row-hover {
        background-color: $highlight-600;
      }
    }

    &.bad-record {
      background-color: $danger-700;

      &.ag-row-hover {
        background-color: $danger-800;
      }

      .ag-cell {
        color: $ui-0;

        &.edited {
          color: $warning-1600;
        }
      }
    }

    &.recalculated-record {
      background-color: $brand-500;

      &.ag-row-hover {
        background-color: $brand-600;
      }

      .ag-cell {
        color: $ui-1000;

        &.edited {
          color: $warning-1300;
        }
      }
    }

    &.ag-row-selected {
      background-color: $brand-1000;

      &.ag-row-hover {
        background-color: $brand-1200;
      }

      .ag-cell {
        color: $ui-0;

        &.edited {
          box-shadow: inset 0 0 0 1px $brand-500;
          color: $warning-700;
        }
      }
    }

    .ag-cell {
      &.edited {
        box-shadow: inset 0 0 0 1px $ui-900;
        color: $warning-1000;
      }
    }
  }

  .ag-pinned-left-header,
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: solid 4px $ui-400;
  }

  .ag-pinned-left-header {
    .ag-header-cell {
      background-color: $ui-200;
    }
  }

  .ag-row-hover {
    background-color: $ui-200;
  }

  .ag-cell-range-selected:not(.ag-cell-inline-editing, .selection-col) {
    background-color: $brand-1200  !important;
    color: $ui-0  !important;

    &.edited {
      color: $warning-700  !important;
    }
  }

  .ag-cell-range-selected:not(.ag-cell-focus, .selection-col) {
    background-color: $brand-1000  !important;
    color: $ui-0  !important;
  }

  .ag-menu {
    font-size: 1.1em;

    .ag-menu-option-active {
      background-color: $ui-200;
    }
  }

  .ag-popup .ag-tooltip {
    background-color: $ui-0;
    padding: 0.25em 0.5em;
    border: solid 1px $ui-400;
    border-radius: 0.25em;
  }
}