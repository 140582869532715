$ui-0: #ffffff;
$ui-100: #f6f6f7;
$ui-200: #edeeef;
$ui-300: #e4e4e5;
$ui-400: #dbdcdd;
$ui-500: #c9cacd;
$ui-600: #b7b8bb;
$ui-700: #93969b;
$ui-800: #6f7279;
$ui-900: #4b4f57;
$ui-1000: #1e232d;

$brand-100: #f5fafe;
$brand-200: #ecf4fd;
$brand-300: #e1eefb;
$brand-400: #d7e9fa;
$brand-500: #c4def8;
$brand-600: #b0d2f5;
$brand-700: #89bdf1;
$brand-800: #62a7eb;
$brand-900: #3a91e6;
$brand-1000: #0975e0;
$brand-1100: #086cce;
$brand-1200: #0867c5;
$brand-1300: #0862bc;
$brand-1400: #0759aa;
$brand-1500: #065098;

$success-100: #f5faf8;
$success-200: #ebf5f0;
$success-300: #e0efe8;
$success-400: #d6eae0;
$success-500: #c2e1d1;
$success-600: #add6c1;
$success-700: #85c2a4;
$success-800: #5cae85;
$success-900: #339966;
$success-1000: #008040;
$success-1100: #00763b;
$success-1200: #007138;
$success-1300: #006c36;
$success-1400: #006131;
$success-1500: #00572c;

$warning-100: #fdfaf7;
$warning-200: #faf4ee;
$warning-300: #f7eee5;
$warning-400: #f5e8dc;
$warning-500: #f0decb;
$warning-600: #ead2b9;
$warning-700: #e1bc97;
$warning-800: #d6a674;
$warning-900: #cc8f51;
$warning-1000: #bf7326;
$warning-1100: #b06a23;
$warning-1200: #a86521;
$warning-1300: #a06120;
$warning-1400: #91571d;
$warning-1500: #824e1a;
$warning-1600: #5d360e;

$danger-100: #fef5f5;
$danger-200: #fdebeb;
$danger-300: #fbe0e0;
$danger-400: #fad6d6;
$danger-500: #f8c2c2;
$danger-600: #f5adad;
$danger-700: #f18585;
$danger-800: #eb5c5c;
$danger-900: #e63333;
$danger-1000: #e00000;
$danger-1100: #ce0000;
$danger-1200: #c50000;
$danger-1300: #bc0000;
$danger-1400: #aa0000;
$danger-1500: #980000;

$highlight-500: #fef8bf;
$highlight-600: #e2d988;